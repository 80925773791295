$bpS: 25rem;
$bpM: 32rem;
$bpL: 40rem;

:root {
	--fMain: Roboto, Helvetica, Arial, sans-serif;
	--fAlt: 'Noto Serif', Georgia, serif;

	--baseRoot: 1em;
	--baseline: 1.65rem;
	--baselineX2: calc(var(--baseline) * 2);
	--baselineX3: calc(var(--baseline) * 3);
	--baselineMedium: calc(var(--baseline) * 1.5);
	--baselineHalf: calc(var(--baseline) / 2);

	@media screen and (min-width: $bpS) {
		--baseRoot: 1.15em;
		--baseline: 1.85rem;
	}
	@media screen and (min-width: $bpM) {
		--baseRoot: 1.25em;
		--baseline: 2rem;
	}

	--hairthin: .085em;
	--thin: .125em;
	--thick: .25em;

	--s50: .3em;
	--s40: .5em;
	--s30: .625em;
	--s20: .75em;
	--s10: .875em;
	--base: 1em; 
	--l10: 1.125em;
	--l20: 1.2em;
	--l30: 1.3em;
	--l40: 1.7em;
	--l50: 2em;
	--l60: 2.75em;
	
	/* Main color values split up into Hue, Saturation and Light  */
	--cMainH: 24; --cMainS: 97%; --cMainL: 43%;
	--cMain: hsl(var(--cMainH), var(--cMainS), var(--cMainL));

	--cMainL10: hsl(var(--cMainH), var(--cMainS), 53%);
	--cMainD10: hsl(var(--cMainH), var(--cMainS), 35%);
	--cBright: white;
	--cText: hsl(0, 0%, 20%);
}
