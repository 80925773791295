.button {
	font-size: 90%;
	border: 2px solid var(--cMain);
	border-radius: 0.2rem;
	display: inline-block;
	margin-top: var(--baseline);
	padding: .2rem .4rem;
	background-color: var(--cBright);
	
	&:hover {
		border-color: var(--cMainD10);
	}

	a {
		text-transform: uppercase;
		font-weight: 600;
		padding: .5em;
		text-decoration: none;
	}
}
