.o-footer {

	margin-top: var(--baselineX2);
	margin-bottom: var(--baseline);

	&__copyright {
		font-size: var(--s20);
		font-style: italic;
		line-height: var(--baselineHalf);
	}

	&__socialWeb {
		font-size: var(--s20);
	}
}
