body {
	background-image: url(/images/softpaper.png);
	background-repeat: repeat;
	font-size: var(--baseRoot);
	margin: 0;
}

#mainWrapper {
	padding: 0 0.5em;
	margin: 0 auto;
	max-width: $bpM;
}

figure {
	margin: var(--baseline) auto;

	img {
		width: 100%;
		margin: 0;
	}

	figcaption {
		font-size: var(--s20);
		line-height: var(--baselineHalf);
		font-style: italic;

		h4 {
			font-size: var(--base);
			margin: 0;
			display: inline-block;
			font-weight: 600;
			text-transform: uppercase;

			&:after {
			content: ":";
			}
		}

		p {
			display: inline;
			margin-left: var(--thick);

		}
	}
}

div.highlight pre {
	white-space: pre-wrap;
	word-break: break-word;
}
