.logo { 
	font-size: 10.5vw;

	@media screen and (min-width: $bpL) {
		font-size: calc(var(--l40) * 2);
	}

	a {
		display: block;
		text-decoration: none;
		height: var(--baselineX2);
		color: var(--cMain); 
	}
}
